import "./App.css";
import { withTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Projects from "./pages/Projects";
import AppHeader from "./components/Header";
import Footer from "./components/Footer";
import LandingPage from "./pages/LandingPage";

function App() {
  return (
    <div className="App">
      <AppHeader></AppHeader>
      <div className="content-container">
        <Routes>
          <Route path="/" element={<div className="test"><LandingPage/></div>} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/projects" element={<Projects />} />
        </Routes>
      </div>

      <Footer className="footer-container"></Footer>
    </div>
  );
}

export default withTranslation("translations")(App);
