const enLang = {
    title:"test En",
    about: "About me",
    projects: "Projects",
    contact: "Contact",
    home: "Home",
    "landing-page-description":"scenograf / designer de spatii cu poveste EN",
    "landing-page-description2":"Hai sa spunem povestea spatiului tau EN",
    "landingpage-button-text":"Find out more",
}

export default enLang