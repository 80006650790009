import * as React from "react";
import { withTranslation } from "react-i18next";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import "./Header.css";
import logo from "./../assets/images/logo.png";
import logoBeije from "./../assets/images/logo_beije.png";
import { Menu } from "@mui/icons-material";

function Header(props) {
  const { t, i18n } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  let container = undefined;
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  React.useEffect(() => {
    container = window !== undefined ? () => window().document.body : undefined;
  }, []);

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <img src={logoBeije} width="100" height="50" />
      <Divider />
      <List>
        <ListItem disablePadding>
          <Link
            to="/home"
            className="header-button"
            style={{
              textDecoration: "none",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button className="header-button-white">{t("home")}</Button>
          </Link>
        </ListItem>
        <ListItem disablePadding>
          <Link
            to="/about"
            className="header-button"
            style={{
              textDecoration: "none",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button className="header-button-white">{t("about")}</Button>
          </Link>
        </ListItem>
        <ListItem disablePadding>
          <Link
            to="/projects"
            className="header-button"
            style={{
              textDecoration: "none",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button className="header-button-white">{t("projects")}</Button>
          </Link>
        </ListItem>
        <ListItem disablePadding>
          <Link
            to="/contact"
            className="header-button"
            style={{
              textDecoration: "none",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button className="header-button-white">{t("contact")}</Button>
          </Link>
        </ListItem>
        <ListItem disablePadding>
          <div className="header-language-container-hamburger">
            <div className="header-language-container">
              <Button
                onClick={() => changeLanguage("ro")}
                sx={{ color: "white", marginRight: "-10px" }}
              >
                RO
              </Button>
              <hr></hr>
              <Button
                onClick={() => changeLanguage("en")}
                sx={{ color: "white", marginLeft: "-10px" }}
              >
                EN
              </Button>
            </div>
          </div>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      <AppBar position="static" className="header" component="nav">
        <Toolbar>
          <img src={logo} width="100" height="50" />
          <Box
            sx={{
              display: { xs: "none", sm: "block" },
              flexGrow: 1,
              marginLeft: "15px",
            }}
          >
            <Link
              to="/home"
              className="header-button"
              style={{ textDecoration: "none" }}
            >
              <Button className="header-button-white">{t("home")}</Button>
            </Link>
            <Link
              to="/about"
              className="header-button"
              style={{ textDecoration: "none" }}
            >
              <Button className="header-button-white">{t("about")}</Button>
            </Link>
            <Link
              to="/projects"
              className="header-button"
              style={{ textDecoration: "none" }}
            >
              <Button className="header-button-white">{t("projects")}</Button>
            </Link>
            <Link
              to="/contact"
              className="header-button"
              style={{ textDecoration: "none" }}
            >
              <Button className="header-button-white">{t("contact")}</Button>
            </Link>
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "block" },
            }}
          >
            <div className="header-language-container">
              <Button
                onClick={() => changeLanguage("ro")}
                sx={{ color: "white", marginRight: "-10px" }}
              >
                RO
              </Button>
              <hr></hr>
              <Button
                onClick={() => changeLanguage("en")}
                sx={{ color: "white", marginLeft: "-10px" }}
              >
                EN
              </Button>
            </div>
          </Box>
          <Box className="hamburger-box" sx={{ display: { sm: "none" } }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <Menu />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          PaperProps={{
            sx: {
              backgroundColor: "rgb(43,43,43)",
              color: "white",
            },
          }}
          anchor="right"
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: 240,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </div>
  );
}

export default withTranslation("translations")(Header);
