import { withTranslation } from "react-i18next";

function CONTACT() {

    return (
        <div>
            CONTACT!!!
        </div>
    )
}

export default withTranslation("translations")(CONTACT);