import { withTranslation } from "react-i18next";

function Projects() {

    return (
        <div>
            PROJECTS!!!
        </div>
    )
}

export default withTranslation("translations")(Projects);