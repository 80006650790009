import { withTranslation } from "react-i18next";
import "./Footer.css";
import { FacebookOutlined, Instagram } from "@mui/icons-material";
import { Typography } from "@mui/material";
import logo from "./../assets/images/logo.png";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-butons-container">
        <div className="footer-button-container footer-button-left ">
          <div className="center-button">
            <FacebookOutlined
              sx={{ fontSize: "medium", color: "white" }}
            ></FacebookOutlined>
            <div className="margin-left">
              <a className="link">
                <Typography>/ammsandu</Typography>
              </a>
            </div>
          </div>
        </div>
        <div className="footer-button-container footer-button-center">
          <img src={logo} width="100" height="50" />
        </div>
        <div className="footer-button-container footer-button-right">
          <div className="center-button">
            <div className="margin-right">
              <a className="link">
                <Typography>@ammsandu</Typography>
              </a>
            </div>
            <Instagram sx={{ fontSize: "medium", color: "white" }}></Instagram>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withTranslation("translations")(Footer);
