import { withTranslation } from "react-i18next";
import './Home.css'

function Home() {

    return (
        <div>
            <div className=""></div>

            HOME!!!
        </div>
    )
}

export default withTranslation("translations")(Home);