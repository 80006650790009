import { withTranslation } from "react-i18next";
import "./LandingPage.css";
import sormea from "./../assets/images/sormea.png";
import logo from "./../assets/images/logo.png";

import { Box, Button, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

function LandingPage(props) {
  const { t, i18n } = props;
  const navigate = useNavigate();
  return (
    <div className="fullscreen">
      <Container
        sx={{
          display: { xs: "none", sm: "block" },
        }}
      >
        <img
          src={sormea}
          className="sormea-img"
          width={"500px"}
          height={"670px"}
        />
      </Container>
      <Container
        sx={{
          display: { xs: "block", sm: "none" },
        }}
      >
        <img
          src={sormea}
          className="sormea-img-mobile"
          width={"230px"}
          height={"309px"}
        />
      </Container>
      <Container
        sx={{
          display: { xs: "none", sm: "block" },
        }}
      >
        <div>
          <div className="outer_circle">
            <div className="inner_circle"></div>
          </div>
        </div>
      </Container>
      <Container
        sx={{
          display: { xs: "block", sm: "none" },
        }}
      >
        <div>
          <div className="outer_circle_mobile">
            <div className="inner_circle_mobile"></div>
          </div>
        </div>
      </Container>
      <Box
        sx={{
          display: { xs: "none !important", sm: "flex !important" },
        }}
        className="landing-page-content"
      >
        <div className="landing-page-text-container">
          <Typography variant="h2">Mădălina SANDU</Typography>
          <Typography>{t("landing-page-description")}</Typography>
          <div className="gap"></div>
          <Typography variant="h5">{t("landing-page-description2")}</Typography>
          <div className="gap"></div>
          <Button
            variant="contained"
            sx={{ background: "rgb(201,149,127)" }}
            onClick={() => {
              navigate("/home");
            }}
          >
            {t("landingpage-button-text")}
          </Button>
          <div>
            <img
              src={logo}
              className="landingpage-logo-img"
              width={"200px"}
              height={"100px"}
            />
          </div>
        </div>
      </Box>
      <Box
        sx={{
          display: { xs: "block", sm: "none" },
        }}
      >
        <div className="landing-page-text-container-mobile">
          <Typography variant="h4">Mădălina SANDU</Typography>
          <Typography>{t("landing-page-description")}</Typography>
          <div className="gap-mobile"></div>
          <Typography variant="h5">{t("landing-page-description2")}</Typography>
          <div className="gap-mobile"></div>
          <Button
            variant="contained"
            sx={{ background: "rgb(201,149,127)" }}
            onClick={() => {
              navigate("/home");
            }}
          >
            {t("landingpage-button-text")}
          </Button>
          <div className="gap-mobile"></div>
          <div>
            <img
              src={logo}
              width={"200px"}
              height={"100px"}
            />
          </div>
        </div>
      </Box>
    </div>
  );
}

export default withTranslation("translations")(LandingPage);
