const roLang = {
    title:"test Ro",
    about: "Despre mine",
    projects: "Proiecte",
    contact: "Contact",
    home: "Home",
    "landing-page-description":"scenograf / designer de spatii cu poveste",
    "landing-page-description2":"Hai sa spunem povestea spatiului tau",
    "landingpage-button-text":"Afla mai multe",
}

export default roLang