import { Box } from "@mui/material";
import { withTranslation } from "react-i18next";

function About(props) {
    const { t, i18n } = props;

    return (
        <div>
            <Box>

            </Box>
        </div>
    )
}

export default withTranslation("translations")(About);